import { Component, OnInit, ElementRef } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MicrosoftAuthService } from "../../pages/auth/services/microsoft-auth.service"

import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { UserSession } from "src/app/pages/auth/interfaces/UserSession.interface";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/state/app.state";
import { selectorUserSession } from "src/app/state/selectors/login.selector";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = true;

  photoProfile;

  userSession: UserSession;
  rol: any;
  rolReplace: any;


  constructor(
    private MicrosoftAuthService: MicrosoftAuthService,
    location: Location,
    private element: ElementRef,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private store: Store<AppState>
  ) {

    // this.isLoggedIn = (localStorage.getItem('isLoggedIn') == 'true') ? true : false;
    // this.name = localStorage.getItem('name');

    this.store.select(selectorUserSession).subscribe((sesion: UserSession) => {
      this.userSession = sesion;
      this.rol = JSON.parse(this.userSession.roles)
      this.rolReplace = this.rol[0].replace('Usuarios.','')
    });

    this.photo();

    this.location = location;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
           // Show loading indicator

      }
      if (event instanceof NavigationEnd) {
           // Hide loading indicator

          if (window.innerWidth < 1200) {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
            this.sidenavOpen = false;
          }
      }

      if (event instanceof NavigationError) {
           // Hide loading indicator

           // Present error to user
          console.log(event.error);
      }
  });

  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
  }

  logout() {
    this.MicrosoftAuthService.logout();
  }

  photo(){
    this.MicrosoftAuthService.photo().subscribe((blob) => {
      var urlCreator = window.URL || window.webkitURL;
        this.photoProfile = this.domSanitizer.bypassSecurityTrustUrl(
          urlCreator.createObjectURL(blob)
        );
    });
  };

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  openSearch() {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function() {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  }
  closeSearch() {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  }
  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
}
