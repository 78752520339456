import { catchError, delay, Observable, retry, throwError } from 'rxjs';
import { HttpClient, HttpHeaders                          } from '@angular/common/http';
import { Injectable                                       } from '@angular/core';
import { Store                                            } from '@ngrx/store';

import { environment          } from 'src/environments/environment';
import { EventResponse, Event } from '../interfaces/event-response';
import { EventDTO             } from '../interfaces/eventDTO';
import { EventResponseSP      } from '../interfaces/event-response-sp';
import { UserSession          } from '../../auth/interfaces/UserSession.interface';
import { selectorUserSession  } from 'src/app/state/selectors/login.selector';
import { AppState             } from 'src/app/state/app.state';
import { UserNotificationResponse } from '../interfaces/user-notification-response';

const _url = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ClassroomsService {

  userSession: UserSession;
  userRoles;

  constructor(private http: HttpClient,
    private store: Store<AppState>) { 
    this.store.select(selectorUserSession).subscribe((session: UserSession) => {
      this.userSession = session;
      this.userRoles = JSON.parse(session.roles);
    });
  }

  //#region ==========> Events <==========
  createEvent(event: Event): Observable<EventResponse>{
    return this.http.post<EventResponse>(`${_url}/events`, event);
  }

  readAllEvents(): Observable<EventResponseSP>{
    return this.http.get<EventResponseSP>(`${_url}/events/${+this.userSession.employeeId}/${this.userRoles[0].slice(9)}`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  readAllActiveEvents(): Observable<EventResponseSP>{
    return this.http.get<EventResponseSP>(`${_url}/events/active/${+this.userSession.employeeId}/${this.userRoles[0].slice(9)}`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }
  
  updateEvent(eventDTO: EventDTO): Observable<EventResponse>{
    return this.http.put<EventResponse>(`${_url}/events`, eventDTO);
  }

  deleteEvent(eventDTO: EventDTO):Observable<EventResponse>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: eventDTO,
    };
    return this.http.delete<EventResponse>(`${_url}/events`, options);
  }

  //#endregion

  

  handleError(error) {
    let statusError = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      statusError = error.status;
    } else {
      // server-side error
      statusError = error.status;
    }
    return throwError(() => new Error(statusError))
  }
}
