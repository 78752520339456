<div class=" header pb-6">
    <div class=" container-fluid">
      <div class=" header-body">
        <div class=" row align-items-center py-4">
          <div class=" col-lg-6 col-7">
            <h6 class=" h2 text-default d-inline-block mb-0">{{breadcrumb.route}}</h6>
            <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4" >
              <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
                <li class=" breadcrumb-item">
                  <a href="javascript:void(0)"> <i class="{{breadcrumb.icon}} text-danger"> </i> </a>
                </li>
                <!-- <li class=" breadcrumb-item">
                  <a href="javascript:void(0)" class="text-default"> {{breadcrumb.childRoute}} </a>
                </li> -->
                <li aria-current="page" class=" breadcrumb-item active text-muted">
                  {{breadcrumb.title}}
                </li>
              </ol>
            </nav>
          </div>
          <div class=" col-lg-6 col-5 text-right">
            <button class="btn btn-icon btn-2 btn-sm btn-danger" type="button" (click)="createEvent()">
              <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
              <span class="btn-inner--text">Nuevo</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class=" container-fluid mt--6">
    <div class=" card mb-4">
      <div class=" card-header"><h3 class=" mb-0">{{breadcrumb.title}}</h3></div>
        <div class="dataTables_wrapper py-4">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="datatable_length">
                <label>
                  Mostrar
                  <select name="datatable_length" aria-controls="datatable" class="form-control form-control-sm"
                    (change)="entriesChange($event)">
                    <option value="10" [selected]="entries == 10">10</option>
                    <option value="25" [selected]="entries == 25">25</option>
                    <option value="50" [selected]="entries == 50">50</option>
                    <option value="-1" [selected]="entries == -1">All</option>
                  </select>
                  registros
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="datatable_filter" class="dataTables_filter">
                <label>
                  <input type="search" class="form-control form-control-sm" placeholder="Buscar registro"
                    aria-controls="datatable" (keyup)="filterTable($event)" />
                </label>
              </div>
            </div>
          </div>
          <mat-grid-list cols="1" *ngIf="loader else divEvents">
            <mat-grid-tile>
                <app-loader *ngIf="loader"></app-loader>
            </mat-grid-tile>
          </mat-grid-list>
          <ng-template #divEvents>
            <ng-scrollbar #scrollable track="all" pointerEventsMethod="scrollbar" [autoWidthDisabled]="false"
              style="height: 800px; min-height: 400px;">
              <ngx-datatable #myTable
                            class="bootstrap selection-cell table table-responsive table-striped" 
                            [columnMode]="'force'" 
                            [headerHeight]="50" 
                            [footerHeight]="50"
                            [rowHeight]="'auto'" 
                            [limit]="entries != -1 ? entries : undefined" 
                            [rows]="dataEventsDb"
                            (activate)="onActivate($event)"
                            [messages]="{emptyMessage: 'No hay eventos registrados.'}"
                            [scrollbarH]="true">

                <ngx-datatable-row-detail [rowHeight]="getHeight" #myDetailRow>
                  <ng-template let-row="row" ngx-datatable-row-detail-template>
                    <div style="padding-left:35px;">
                      <div class="row">
                        <div class="auto"></div>
                        <div class="col-1" *ngIf="row.status">
                          <button class="btn btn-sm btn-block btn-outline-primary" placement="left" style="height: 40px; width: 90px;"
                            (click)="updateRecord(row)">
                            <i class="fas fa-edit"> Modificar</i>
                          </button>
                        </div>
                        <div class="col-1" *ngIf="row.status">
                          <button class="btn btn-sm btn-block btn-outline-red" placement="left" style="height: 40px; width: 90px;"
                            (click)="cancelEvent(row)">
                            <i class="fas fa-calendar-times"> Cancelar</i>
                          </button>
                        </div>
                      </div>
                      <br>
                    </div>
                  </ng-template>
                </ngx-datatable-row-detail>

                <ngx-datatable-column name="Accion" [width]="70" [resizeable]="false" [sortable]="false"
                  [draggable]="false" [canAutoResize]="false">
                  <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                    <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded"
                      [class.datatable-icon-down]="expanded" title="Acciones" (click)="toggleExpandRow(row)">
                    </a>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Folio"  [flexGrow]="9">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span>{{column.name}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.idEvent}}
                  </ng-template>
                </ngx-datatable-column>
                [scrollbarH]="true">
                [scrollbarH]="true">

                <ngx-datatable-column name="Actividad">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span>{{column.name}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.activity}}
                  </ng-template>
                </ngx-datatable-column>
                [scrollbarH]="true">

                <ngx-datatable-column name="Descripción">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span>{{column.name}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.description}}
                  </ng-template>
                </ngx-datatable-column>
                [scrollbarH]="true">

                <ngx-datatable-column name="Fecha">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span>{{column.name}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.eventDate|date:"mediumDate"}} <br>
                    Inicio: {{row.startHour|date:"shortTime"}} hrs<br>
                    Fin: {{row.endHour|date:"shortTime"}} hrs
                  </ng-template>
                </ngx-datatable-column>
                [scrollbarH]="true">

                <ngx-datatable-column name="# Asistentes">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span>{{column.name}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.attendeesNumber}}
                  </ng-template>
                </ngx-datatable-column>
                [scrollbarH]="true">

                <ngx-datatable-column name="Convoca">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span>{{column.name}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.nombreEmpleado}}
                  </ng-template>
                </ngx-datatable-column>
                [scrollbarH]="true">
                [scrollbarH]="true">

                <ngx-datatable-column name="Distribución">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span>{{column.name}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <img alt="{{row.distribution}}" src="./assets/img/distribuciones/{{row.distribution}}.jpg" width="50px">
                    {{row.distribution}}
                  </ng-template>
                </ngx-datatable-column>
                [scrollbarH]="true">
                [scrollbarH]="true">

                <ngx-datatable-column name="Tipo de Evento">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span>{{column.name}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.typeEvent}}
                  </ng-template>
                </ngx-datatable-column>
                [scrollbarH]="true">
                [scrollbarH]="true">

                <ngx-datatable-column name="Lugar">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span>{{column.name}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.place}}
                  </ng-template>
                </ngx-datatable-column>
                [scrollbarH]="true">

                <ngx-datatable-column name="Organizador">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span>{{column.name}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.organizer}}
                  </ng-template>
                </ngx-datatable-column>
                [scrollbarH]="true">

                <ngx-datatable-column name="Estatus" sortable="false" prop="id">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span [ngSwitch]="row.status">
                    <span *ngSwitchCase="true" class=" badge badge-dot mr-4">
                      <i class=" bg-success"> </i>
                      <span class="text-success"> Activo </span>
                    </span>
                    <span *ngSwitchCase="false" class=" badge badge-dot mr-4">
                      <i class=" bg-red"> </i>
                      <span class="text-danger"> Inactivo </span>
                    </span>
                    </span>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </ng-scrollbar>
          </ng-template>
        </div>
    </div>
  </div>