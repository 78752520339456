<nav
  class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
  id="sidenav-main"
  (mouseover)="onMouseEnterSidenav()"
  (mouseout)="onMouseLeaveSidenav()"
>
<perfect-scrollbar>
  <div class="scrollbar-inner">
    <div class="sidenav-header d-flex align-items-center">
      <a class="navbar-brand" [routerLink]="['/']">
        <img
          src="assets/img/ieeg/logoColor.png"
          class="navbar-brand-img"
          alt="logoNav"
        />
      </a>
      <div class="ml-auto">
        <!-- Sidenav toggler -->
        <div
          class="sidenav-toggler d-none d-xl-block"
          data-action="sidenav-unpin"
          data-target="#sidenav-main"
          (click)="minimizeSidebar()"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar-inner">
      <div class="collapse navbar-collapse" id="sidenav-collapse-main">
        <!-- Collapse header -->
        <ul class="navbar-nav">
          <li *ngFor="let menuitem of menuItems" class="nav-item">
            <!--If is a single link-->
            <a
              routerLinkActive="active"
              [routerLink]="[menuitem.path]"
              *ngIf="menuitem.type === 'link' 
                &&
                (
                  (menuitem.path=== 'events' && usuarioRol('SuperAdmin')) ||
                  (menuitem.path=== 'events' && usuarioRol('Usuario')) ||
                  (menuitem.path=== 'events' && usuarioRol('Supervisor')) ||
                  (menuitem.path=== '/' && usuarioRol('SuperAdmin')) ||
                  (menuitem.path=== '/' && usuarioRol('Usuario'))    ||
                  (menuitem.path=== '/' && usuarioRol('Supervisor'))
                )"
              class="nav-link"
            >
              <i class="ni {{ menuitem.icontype }}"></i>
              <span class="nav-link-text">{{ menuitem.title }}</span>
            </a>
            <!--If it have a submenu-->
            <a
              data-toggle="collapse"
              routerLinkActive="active"
              *ngIf="
                menuitem.type === 'sub' 
                &&
                (menuitem.path=== 'catalogs' && usuarioRol('SuperAdmin')) 
              "
              (click)="menuitem.isCollapsed = !menuitem.isCollapsed"
              [attr.aria-expanded]="!menuitem.isCollapsed"
              [attr.aria-controls]="menuitem.collapse"
              class="nav-link"
            >
              <i class="ni {{ menuitem.icontype }}"></i>
              <span class="nav-link-text">{{ menuitem.title }}</span>
            </a>

            <!--Display the submenu items-->
            <div
              id="{{ menuitem.collapse }}"
              class="collapse"
              *ngIf="menuitem.type === 'sub'"
              [collapse]="menuitem.isCollapsed"
              [isAnimated]="true"
            >
              <ul class="nav nav-sm flex-column">
                <li
                  *ngFor="let childitems of menuitem.children"
                  class="nav-item"
                >
                  <!--If is a single link-->
                  <a
                    routerLinkActive="active"
                    [routerLink]="[menuitem.path, childitems.path]"
                    class="nav-link"
                    *ngIf="childitems.type === 'link' 
                    &&
                      (
                        (childitems.path === 'activities-organizers' && usuarioRol('SuperAdmin')) || 
                        (childitems.path === 'distributions' && usuarioRol('SuperAdmin') ||
                        (childitems.path === 'events-type' && usuarioRol('SuperAdmin')) ||
                        (childitems.path === 'places' && usuarioRol('SuperAdmin')) ||
                        (childitems.path === 'users-notification' && usuarioRol('SuperAdmin')) 
                      )
                      )"
                  >
                    {{ childitems.title }}
                  </a>
                  <!--If it have a submenu-->
                  <a
                    data-toggle="collapse"
                    (click)="childitems.isCollapsed = !childitems.isCollapsed"
                    [attr.aria-expanded]="!childitems.isCollapsed"
                    [attr.aria-controls]="childitems.collapse"
                    *ngIf="childitems.type === 'sub'"
                    class="nav-link"
                  >
                    {{ childitems.title }}
                  </a>
                  <!--Display the submenu items-->
                  <div
                    id="{{ childitems.collapse }}"
                    class="collapse"
                    *ngIf="childitems.type === 'sub'"
                    [collapse]="childitems.isCollapsed"
                    [isAnimated]="true"
                  >
                    <ul class="nav">
                      <li
                        *ngFor="let childitem of childitems.children"
                        class="nav-item"
                      >
                        <a href="javascript:void(0)" class="nav-link">
                          {{ childitem.title }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <!-- Divider -->
        <hr class="my-3" />
        <!-- Heading -->

      </div>
    </div>
  </div>
</perfect-scrollbar>

</nav>
