import { NgbActiveModal, NgbDatepickerConfig, NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig, BsDatepickerViewMode, BsLocaleService                } from 'ngx-bootstrap/datepicker';
import { UntypedFormGroup, UntypedFormControl, Validators                         } from '@angular/forms';
import { Component, Input, OnInit                                                 } from '@angular/core';
import { DatePipe, formatDate                                                     } from "@angular/common";
import { registerLocaleData                                                       } from '@angular/common';
import { defineLocale                                                             } from 'ngx-bootstrap/chronos';
import { esLocale                                                                 } from 'ngx-bootstrap/locale';
import { Store                                                                    } from '@ngrx/store';
import   localeES                                                                   from "@angular/common/locales/es";
import   Swal                                                                       from 'sweetalert2';
import { NgxSpinnerService                                                        } from 'ngx-spinner';

import { selectorUserSession   } from 'src/app/state/selectors/login.selector';
import { NotificationService   } from 'src/app/shared/services/notification.service';
import { ActivityOrganizer     } from '../../../interfaces/activity-organizer-response';
import { ClassroomsService     } from '../../../services/classrooms.service'
import { CatalogService        } from '../../../services/catalogs.service';
import { Distribution          } from '../../../interfaces/distribution-response';
import { UserSession           } from '../../../../auth/interfaces/UserSession.interface';
import { TypeEvent             } from '../../../interfaces/type-event-response';
import { AppState              } from '../../../../../state/app.state';
import { EventDTO              } from '../../../interfaces/eventDTO';
import { Event                 } from '../../../interfaces/event-response';
import { Place                 } from '../../../interfaces/place-response';

registerLocaleData(localeES, "es");

@Component({
  selector    :  'app-event-modal',
  templateUrl :  './event-modal.component.html',
  styleUrls   : ['./event-modal.component.scss'],
  providers   : [DatePipe]
})
export class EventModalComponent implements OnInit {
  
  datepipe    : DatePipe = new DatePipe('es-ES')
  userSession : UserSession;
  model       : NgbDateStruct;

  @Input() titleModal;
  @Input() event;
  
  checkReplica = false;
  event1       = false;
  haveEvent    = false;
  isLoading    = true;

  attenderNumber      : number;
  activityOrganizers! : ActivityOrganizer[];
  date                : string;
  distributions!      : Distribution[];
  employeeName        : String;
  eventDTO            : EventDTO;
  events              : Event[] = [];
  places!             : Place[];
  typeEvents!         : TypeEvent[];
  userRoles           : [];

  yearFull : number = Number('0' + (new Date().getFullYear()).toString());
  month    : number = Number('0' + (new Date().getMonth() + 1).toString().slice(-2));
  day      : number = Number((new Date().getUTCDate()).toString());

  markDisabled;

  form = new UntypedFormGroup({
    idEvent:             new UntypedFormControl(0 , []),
    activity:            new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    description:         new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    eventDate:           new UntypedFormControl('', []),
    rangeDate:           new UntypedFormControl([], [Validators.required]),
    startHour:           new UntypedFormControl('', [Validators.required]),
    endHour:             new UntypedFormControl('', [Validators.required]),
    creationDate:        new UntypedFormControl('', []),
    attendeesNumber:     new UntypedFormControl('', [Validators.required, Validators.max(50)]),
    status:              new UntypedFormControl('', []),
    idUsuario:           new UntypedFormControl('', []),
    idDistribution:      new UntypedFormControl('', [Validators.required]),
    idPlace:             new UntypedFormControl('', [Validators.required]),
    idTypeEvent:         new UntypedFormControl('', [Validators.required]),
    idActivityOrganizer: new UntypedFormControl('', [Validators.required]),
    totalDays:           new UntypedFormControl('', [])
  });

  minDate = new Date();
  maxDate = new Date();
  rangeDate: Date[];

  bsConfig?: Partial<BsDatepickerConfig>;
  minMode  : BsDatepickerViewMode = 'day';

  dias = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];

  constructor(private _catalogsService:    CatalogService,
              private _classroomService:   ClassroomsService,
              private _datepipe:           DatePipe,
              public  activeModal:         NgbActiveModal,
              private notificationService: NotificationService,
              private store:               Store<AppState>,
              private _spinner:            NgxSpinnerService,
              private config:              NgbDatepickerConfig,
              private calendar:            NgbCalendar,
              private localeService:       BsLocaleService,
              )
  {
    // Configuración del DatePicker para restringir los días en que se pueden programar los eventos
    // customize default values of datepickers used by this component tree
    config.minDate = {year: this.yearFull, month: this.month, day: this.day};
    // config.maxDate = {year: 2099, month: 12, day: 31};

    // days that don't belong to current month are not visible
    config.outsideDays = 'hidden';

    this.markDisabled = (date: NgbDate) => calendar.getWeekday(date) >= 6;

    this.store.select(selectorUserSession).subscribe((sesion: UserSession) => {
    this.userRoles = JSON.parse(sesion.roles);
    this.userSession = sesion;
    });

    defineLocale('es', esLocale);
    this.localeService.use('es');

    const weekday = this.minDate.getDay();
    (this.dias[weekday] === 'Viernes') ? this.minDate.setDate(this.minDate.getDate() + 3) : this.minDate.setDate(this.minDate.getDate() + 1);

    this.rangeDate = [this.minDate, this.minDate];
    this.bsConfig = {
      isAnimated: true,
      // containerClass: 'theme-default'
      containerClass: 'theme-dark-blue',
      minMode: this.minMode,
      // rangeInputFormat: 'MMMM YYYY',
      showWeekNumbers: false,
    };
  }

  isDisabled = (date: NgbDate, current: { month: number; year: number }) => date.month !== current.month;
	isWeekend  = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;

  ngOnInit(): void {
    this._spinner.show();
    // Create date to prevent select previous dates.
    this.date      = new Date().toISOString().slice(0, 10);
    this.isLoading = false;

    this.loadFormEvent();
    this.readDistributions();
    this.readPlaces();
    this.readTypeEvent();
    this.readAllEvents();
    this.readActivityOrganizer()
  }

  loadFormEvent() {
    if (this.event != undefined) {

      let formattedEventDate = this.datepipe.transform(this.event.eventDate, 'yyyy-MM-dd HH:mm:ss')
      let eventDateDB = new Date(formattedEventDate);
      this.rangeDate = [eventDateDB, eventDateDB]

      var eventDate = this.event.eventDate.split("T");
      var dEventDate = eventDate[0];

      var startHour = this.event.startHour.split("T");
      var tStartHour = startHour[1];

      var endHour = this.event.endHour.split("T");
      var tEndHour = endHour[1];

      this.form.patchValue({
        idEvent:             this.event.idEvent,
        activity:            this.event.activity,
        description:         this.event.description,
        eventDate:           dEventDate,
        startHour:           tStartHour,
        endHour:             tEndHour,
        creationDate:        this.event.creationDate,
        attendeesNumber:     this.event.attendeesNumber,
        status:              this.event.status,
        idUsuario:           this.event.idUsuario,
        idDistribution:      this.event.idDistribution,
        idPlace:             this.event.idPlace,
        idTypeEvent:         this.event.idTypeEvent,
        idActivityOrganizer: this.event.idActivityOrganizer,
      });
    }
  }

  readDistributions(){
    this.isLoading       = true;
    this._catalogsService.readAllActiveDistributions().subscribe({
      next: (resp) => {
        this.distributions = resp.data;
        this.isLoading       = false;
        this._spinner.hide();
      },
      error: (e) => {
        if (e.message === '0') {
          this.notificationService.showNotification('danger', 'Error', 'No hay comunicación con el servidor.', 'fa fa-close');
        }else{
          this.notificationService.showNotification('danger', 'Error', e.error, 'fa fa-close');
        }
      }
    });
  }

  readPlaces(){
    this.isLoading       = true;
    this._catalogsService.readAllPlaces().subscribe({
      next: (resp) => {
      this.places = resp.data;
      this.isLoading       = false;
      if (resp) {
      }
      },
      error: (e) => {
        if (e.message === '0') {
          this.notificationService.showNotification('danger', 'Error', 'No hay comunicación con el servidor.', 'fa fa-close');
        }else{
          this.notificationService.showNotification('danger', 'Error', e.error, 'fa fa-close');
        }
      }
    });
  }

  readTypeEvent(){
    this.isLoading = true;
    this._catalogsService.readAllTypeEvents().subscribe({
      next: (resp) => {
      this.typeEvents = resp.data;
      this.isLoading = false;
      if (resp) {
      }
      },
      error: (e) => {
        if (e.message === '0') {
          this.notificationService.showNotification('danger', 'Error', 'No hay comunicación con el servidor.', 'fa fa-close');
        }else{
          this.notificationService.showNotification('danger', 'Error', e.error, 'fa fa-close');
        }
      }
    });
  }

  readAllEvents(){
    this.isLoading = true;
    this._classroomService.readAllActiveEvents().subscribe({
      next: (resp) => {
      this.events = resp.data;
      this.isLoading = false;
      },
      error: (e) => {
        if (e.message === '0') {
          this.notificationService.showNotification('danger', 'Error', 'No hay comunicación con el servidor.', 'fa fa-close');
        }else{
          this.notificationService.showNotification('danger', 'Error', e.error, 'fa fa-close');
        }
      }
    });
  }

  readActivityOrganizer(){
    this.isLoading = true;
    this._catalogsService.readAllActivityOrganizers().subscribe({
      next: (resp) => {
        this.activityOrganizers = resp.data;
        this.isLoading = false;
      },
      error: (e) => {
        if (e.message === '0') {
          this.notificationService.showNotification('danger', 'Error', 'No hay comunicación con el servidor.', 'fa fa-close');
        }else{
          this.notificationService.showNotification('danger', 'Error', e.error, 'fa fa-close');
        }
      }
    });
  }

  createEvents(){
    this._spinner.show();
    this.isLoading       = true;
    var datePlus24hrs    = new Date();
    datePlus24hrs.setSeconds(86400);
    let hayError = false;
    let dateReplicatedEvent;

    // let nDias: number = this.form.value.totalDays;
    let eventDate = this.rangeDate[0];
    // let ffinal = this.rangeDate[1];
    let difference = Math.abs(this.rangeDate[1].getTime() - this.rangeDate[0].getTime());
    let nDias = Math.ceil(difference / (1000 * 3600 * 24)) + 1;

    //Date Configuration
    const format = 'MM/dd/yyyy HH:mm:ss z';
    const locale = 'eS-ES';
    this.eventDTO  = this.form.value;
    const rol = this.userRoles.filter(element => element === `Usuarios.SuperAdmin`);
    const placeSpecialRol = this.places.filter(place => place.idPlace == this.form.value.idPlace)
    if (placeSpecialRol[0].needSpecialRole && rol.length == 0) {
      Swal.fire({
        icon:  'error',
        title: 'No tiene permiso para apartar la Sala de Consejeros.',
        text:  'Por favor, pruebe con otro lugar.',
      });
      this.isLoading = false;
      this._spinner.hide();
      return;
    }
    this.isLoading = true;
    this.eventDTO.idUsuario = +this.userSession.employeeId;

    // Event Date
    // const eventDate = new Date(this.form.value.eventDate.year,
    //                         this.form.value.eventDate.month - 1,
    //                         this.form.value.eventDate.day);
    this.eventDTO.eventDate = new Date(eventDate);

    // Hour Start Event
    var startHourForm = (this.eventDTO.startHour).toString().split(":");
    var hStartHour = +startHourForm[0];
    var mStartHour = +startHourForm[1];
    var startHour  = new Date(this.eventDTO.eventDate.getFullYear(), this.eventDTO.eventDate.getMonth(), this.eventDTO.eventDate.getDate(), hStartHour, mStartHour, 0);
    this.eventDTO.startHour = startHour;

    // Hour End Event
    var vEndHour = (this.eventDTO.endHour).toString().split(":");
    var hEndHour = +vEndHour[0];
    var mEndHour = +vEndHour[1];
    var endHour  = new Date(this.eventDTO.eventDate.getFullYear(), this.eventDTO.eventDate.getMonth(), this.eventDTO.eventDate.getDate(), hEndHour, mEndHour, 0);
    this.eventDTO.endHour = endHour;
    
    // Creation Date
    const eventCreationDate = formatDate(datePlus24hrs, format, locale);
    this.eventDTO.creationDate = new Date(eventCreationDate);
    
    this.eventDTO.status = true;

    // Verify if the event lasts 15 minutes
    if (((this.eventDTO.endHour.getTime() - this.eventDTO.startHour.getTime()) / 60000) < 15) {
      Swal.fire({
        icon:  'warning',
        title: 'El evento debe ser de mínimo 15 minutos.',
        text:  'Favor de corregir el horario.',
      });
      this.isLoading = false;
      this._spinner.hide();
      hayError = true;
    }

    // Verify if the event is programmed within 24 hours
    if (datePlus24hrs.getTime() > this.eventDTO.startHour.getTime()){
      Swal.fire({
        icon:  'warning',
        title: 'Debe programar el evento mínimo 24 horas antes de su inicio.',
        text:  'Favor de corregir el horario.',
      });
      this.isLoading = false;
      this._spinner.hide();
      hayError = true;
    }

    if (this.events != null){
      for (let index = 1; index <= nDias; index++) {
        let horaIForm = this.eventDTO.startHour;
        let horaFForm = this.eventDTO.endHour;
        for( const _event of this.events) {
          const HoraIDB = new Date(_event.startHour);
          HoraIDB.setMinutes(-14);

          const HoraFDB = new Date(_event.endHour);
          HoraFDB.setMinutes(HoraFDB.getMinutes()+14);
          debugger
          if (this.titleModal === 'Editar elemento'){
            if (
                (
                  (
                    HoraIDB <= horaIForm &&
                    HoraFDB >= horaFForm &&
                    _event.idPlace.toString() === this.form.value.idPlace.toString()
                  ) ||
                  (
                    horaFForm >= HoraIDB &&
                    horaIForm <= HoraFDB &&
                    _event.idPlace.toString() === this.form.value.idPlace.toString()
                  ) 
                ) && 
                this.form.value.idEvent.toString() != _event.idEvent.toString()
              )
              {
                this.haveEvent = true;
                dateReplicatedEvent = this._datepipe.transform( this.eventDTO.eventDate, 'dd-MM-yyyy');
                break;
              }
          }
          else{
            if (
                (
                  (
                    HoraIDB <= horaIForm &&
                    HoraFDB >= horaFForm &&
                    _event.idPlace.toString() === this.form.value.idPlace.toString()
                  ) ||
                  (
                    horaFForm >= HoraIDB &&
                    horaIForm <= HoraFDB &&
                    _event.idPlace.toString() === this.form.value.idPlace.toString()
                  )
                ) && 
                this.form.value.idEvent.toString() != _event.idEvent.toString() &&
                _event.status != false
              )
            {
              this.haveEvent = true;
              dateReplicatedEvent = this._datepipe.transform( this.eventDTO.eventDate, 'dd-MM-yyyy');
              break;
            }
          }
        }
        
        if (nDias>1) {
          const weekday = this.eventDTO.eventDate.getDay();
          if (this.dias[weekday] === 'Viernes'){
            // Add 3 days to date
            horaIForm.setDate(this.eventDTO.startHour.getDate() + 3);
            horaFForm.setDate(this.eventDTO.endHour.getDate() + 3);
            index = index + 2;
          }else{
            // Add 1 day to date
            horaIForm.setDate(horaIForm.getDate() + 1);
            horaFForm.setDate(horaFForm.getDate() + 1);
          }
        }
      }
    }

    if (this.haveEvent != false){
      Swal.fire({
        icon:  'error',
        title: `Ya hay un evento programado el día ${dateReplicatedEvent}.`,
        text:  'Intente con otra fecha por favor.',
      });
      this.haveEvent = false;
      this.event1 = false;
      this.isLoading = false;
      this._spinner.hide();
      hayError = true;
    }

    if (!hayError) {
      // Edit
      if (this.titleModal === 'Editar elemento'){
        // If there is no scheduled event, program that is being generated.
        if (this.haveEvent === false){
          this._classroomService.updateEvent(this.eventDTO).subscribe({
            next: (v) => {
              this._spinner.hide();
              if (v.result == 1) {
                this.notificationService.showNotification('success', 'Éxito', v.message, 'fas fa-check');
              }else{
                this.notificationService.showNotification('danger', 'Error', v.message, 'fa fa-close');
              }
              this.activeModal.close('saved');
            },
            error: (e) => {
              if (e.name === "HttpErrorResponse" && e.status === 0) {
                this.notificationService.showNotification('danger', 'Error', 'No hay comunicación con el servidor.', 'fa fa-close');
                this.isLoading = false;
                this._spinner.hide();
              }else{
                this.notificationService.showNotification('danger', 'Error', e.error, 'fa fa-close');
                this.isLoading = false;
                this._spinner.hide();
              }
            }
          });
        }
        else{
          Swal.fire({
            icon:  'error',
            title: 'Ya hay un evento programado en ese horario.',
            text:  'Por favor, pruebe con otro.',
          });
          this.haveEvent = false;
          this.event1 = false;
          this.isLoading = false;
          this._spinner.hide();
        }
      }else{ // New
        for (let index = 1; index <= nDias; index++) {
          setTimeout(()=>{
            this._classroomService.createEvent({... this.eventDTO}).subscribe({
              next: (v) => {
                this.notificationService.showNotification('success', 'Éxito', v.message, 'fas fa-check');
                this.activeModal.close('saved');
              },
              error: (e) => {
                if (e.status == 403) {
                  this._spinner.hide();
                  this.isLoading = false;
                  this.notificationService.showNotification('danger', 'Error', 'Acceso denegado, favor de contactar a la UTSIT', 'fa fa-close');
                }else{
                  this._spinner.hide();
                  this.isLoading = false;
                  this.notificationService.showNotification('danger', 'Error', e.error, 'fa fa-close');
                }
              },
            });
            if (nDias>1) {
              const weekday = this.eventDTO.eventDate.getDay();
              const eventDate = formatDate(this.eventDTO.eventDate, format, locale);
              const startHour = formatDate(this.eventDTO.startHour, format, locale);
              const endHour = formatDate(this.eventDTO.endHour, format, locale);
              // Event Date
              this.eventDTO.startHour = new Date(startHour);
              this.eventDTO.endHour   = new Date(endHour);
              this.eventDTO.eventDate = new Date(eventDate);
              if (this.dias[weekday] === 'Viernes'){
                // Add 3 days to date
                this.eventDTO.eventDate.setDate(this.eventDTO.eventDate.getDate() + 3);
                this.eventDTO.startHour.setDate(this.eventDTO.startHour.getDate() + 3);
                this.eventDTO.endHour.setDate(this.eventDTO.endHour.getDate() + 3);
                index = index + 2;
              }else{
                // Add 1 day to date
                this.eventDTO.eventDate.setDate(this.eventDTO.eventDate.getDate() + 1);
                this.eventDTO.startHour.setDate(this.eventDTO.startHour.getDate() + 1);
                this.eventDTO.endHour.setDate(this.eventDTO.endHour.getDate() + 1);
              }
            }
          }, 500);
        }
      }
    }
  }

  onChangePlace(event: any) {
    this.places.forEach(place => {
      if (place.idPlace === parseInt(event.target.value)) {
        this.attenderNumber = place.capacity;
      }
    });
  }

  validarForm(campo: string) {
    return this.form.controls[campo].errors
        && this.form.controls[campo].touched
  }

  close(){
    this.activeModal.close('Close');
  }
}
