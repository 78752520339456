import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AppState } from 'src/app/state/app.state';
import { selectorUserSession } from 'src/app/state/selectors/login.selector';
import { UserSession } from '../interfaces/UserSession.interface';
import { MicrosoftAuthService } from '../services/microsoft-auth.service';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftRolesGuard implements CanActivate {

  sesion$: Observable<UserSession>;
  loading: Observable<boolean> = new Observable();

  constructor(private _router: Router, private store: Store<AppState>, private _auth: MicrosoftAuthService, private msalAuthService: MsalService,) {

    this.store.select(selectorUserSession).subscribe(data => {
      if(!data.roles){
        this._auth.login();

        setTimeout(() => {
          this.sesion$ = this.store.select(selectorUserSession);
        }, 0);
      }
      
    })

   }
  


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    return new Observable(observer => {
      setTimeout(() => {
        let test = this.store.select(selectorUserSession).pipe(
          map((sesion: UserSession) => {
            if(sesion.roles){
              const rolesUsuario = JSON.parse(sesion.roles);
              const rolesRuta: [] = next.data.rolUsuario;
  
              let allow: boolean = false;

              if(rolesRuta.some(e => rolesUsuario.includes(e))){
                allow = true; 
                observer.next(allow);
                return allow
              }
              else {
                if(sesion.isLoggedIn === true){
                  this._router.navigate(['/start']);
                }
                else{
                  this._router.navigate(['/login']);
                }
                
                return allow;
              }
          }
            
          })
        );

        test.subscribe(data => {});
      }, 0);
    })

    // let rol = JSON.parse(localStorage.getItem('roles'));
    
    // let allow = rol.indexOf(next.data.rolUsuario) > -1;

    // let permiso:boolean = false

    // next.data.rolUsuario.forEach(e => {
    //   if(e == rol)
    //   permiso = true;
    // });
    
    // if (permiso){
    //   return true;
    // }

    
    // if (allow == false)
    //   this._router.navigate(['/']);

    // return allow;
  }
  
}