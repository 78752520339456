import { state } from "@angular/animations";
import { createSelector } from "@ngrx/store";
import { AppState } from '../app.state';
import { loginState } from '../interfaces/loginState.interface';

export const selectorUserSessionFeature = (state: AppState) => state.login;

export const selectorLoading = createSelector(
    selectorUserSessionFeature,
    (state: loginState) => state.loading
  );

export const selectorUserSession = createSelector(
    selectorUserSessionFeature,
    (state: loginState) => state.userSession
)