import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders          } from '@angular/common/http';
import { AuthenticationResult             } from '@azure/msal-browser';
import { MsalService                      } from '@azure/msal-angular';
import   jwt_decode                         from 'jwt-decode';
import { Observable                       } from 'rxjs';
import { Router                           } from '@angular/router';
import { map                              } from 'rxjs/operators';

import { selectorLoading } from 'src/app/state/selectors/login.selector';
import { loginSuccess    } from 'src/app/state/actions/login.actions';
import { environment     } from 'src/environments/environment';
import { UserSession     } from '../interfaces/UserSession.interface';
import { AppState        } from 'src/app/state/app.state';
import { Store           } from '@ngrx/store';

const _scopesAzure = environment.scopesAzure;
const _me          = environment.meAzure;

@Injectable({
  providedIn: 'root'
})
export class MicrosoftAuthService {
  sesion: Observable<UserSession> = new Observable();
  @Output() msgResponse: EventEmitter<any> = new EventEmitter();
  constructor(
    private authService: MsalService,
    private router     : Router,
    private store      : Store<AppState>,
    private http       : HttpClient
  ) { }

  public handleRedirectPromise() {
    this.authService.instance.handleRedirectPromise().then(res => {
      if (res != null && res.account != null) {
        this.authService.instance.setActiveAccount(res.account)
      }
    })
  }

  public login() {
    if (!this.isLoggedIn()) {
    this.authService.loginPopup()
      .subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
          // Token generado por el servicio de login, al hacer la llamada al active directory
          // Este token sirve para obtener la foto de perfil del usuario
          // Es necesario activar la lectura del perfil de usuario en el app.module
          // localStorage.setItem("tokenMSAL", response.accessToken);
          this.getUser(response.accessToken);
      }, error => {
        this.msgResponse.emit({ mensaje: "Error de autenticación", error: true });
      });
    }else{   
      this.getUser();
    }
  }

  private isLoggedIn(): boolean {
    return this.authService.instance.getActiveAccount() != null
  }

  private getToken() {
    return this.authService.acquireTokenSilent({
      scopes: [_scopesAzure]
    }).pipe(
      map(res => res.accessToken)
    )
  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  private getUser(tokenMSAL?) {
    let _userSession: UserSession;
    if (this.isLoggedIn()) {
      this.getToken().subscribe(async token => {
        const tokenInfo = this.getDecodedAccessToken(token);
        if (tokenInfo.roles) {
          _userSession = {
            isLoggedIn: true,
            name: tokenInfo.name,
            email: tokenInfo.unique_name,
            roles: JSON.stringify(tokenInfo.roles),
            // roles: JSON.stringify(['Usuarios.SuperAdmin']),
            // roles: JSON.stringify(['Usuarios.Usuario']),
            // roles: JSON.stringify(['Usuarios.Supervisor']),
            tokenMSAL: tokenMSAL,
          };

          await this.ubicacion().subscribe(resp => {
            _userSession.idUbicacion = parseInt(resp['value']);
          });

          await this.officeLocation().subscribe(resp => {
            _userSession.officeLocation = parseInt(resp['value'])
          });

          await this.employeeId().subscribe(resp => {
            _userSession.employeeId = parseInt(resp['value'])
          });

          this.msgResponse.emit({ mensaje: "Inicio de sesión satisfactorio", error: false });

          if(tokenMSAL){
            this.router.navigate(['/']);
          }

          this.store.select(selectorLoading).subscribe(select => {
            setTimeout(() => {
              if(select){
                this.router.navigate(['/']);
              }
              this.store.dispatch(loginSuccess(
                { userSession : _userSession}
                ))
              }, 1000);
          });
        }
        else {
          this.msgResponse.emit({ mensaje: "Sin permiso para acceder a la aplicación", error: true });
        }
      });
    }
  }

  public logout() {
    this.authService.logout();
  }

  public photo(): Observable<Blob> {
    const token   = localStorage.getItem('tokenMSAL');
    let myHeaders = new HttpHeaders();
    myHeaders.append('Content-Type', 'image/*');
    myHeaders.append('Authorization', `Bearer ${token}`);

    return this.http
      .get(`${_me}/photo/$value`, {
        responseType: 'blob',
        headers: myHeaders,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  private ubicacion() {
    return this.http.get(`${_me}/city`);
  }

  private officeLocation() {
    return this.http.get(`${_me}/officeLocation`);
  }

  private employeeId() {
    return this.http.get(`${_me}/employeeId`);
  }
}
