export const environment = {
  production: true,
  apiUrl: "https://api.aulas.ieeg.mx/api",

  //Login Azure
  redirectApplicationAzure: "https://apartadodeaulas.ieeg.mx",
  scopesAzure: "api://8561709c-7585-4625-bf9e-b82eb445d355/Auth.AD",
  clientIdAzure: "4d7fac06-9ee6-4c33-832a-006ad114d5cb",
  authorityAzure: "https://login.microsoftonline.com/6d88d07c-1672-4d16-ab8c-3af2ef736fe7",
  meAzure: 'https://graph.microsoft.com/v1.0/me'
};
