import { ActionReducerMap } from '@ngrx/store'
import { loginState } from './interfaces/loginState.interface';
import { loginReducer } from './reducers/login.reducers';

export interface AppState {
    login: loginState
}

export const ROOT_REDUCERS:  ActionReducerMap<AppState> = {
    login: loginReducer
}