import { NgModule             } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule         } from "@angular/common";
import { BrowserModule        } from "@angular/platform-browser";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent  } from "./layouts/auth-layout/auth-layout.component";
import { MicrosoftLoginGuard  } from "./pages/auth/guards/microsoft-login.guard";
import { MicrosoftRolesGuard  } from './pages/auth/guards/microsoft-roles.guard';
import { EventsListComponent } from './pages/classrooms/components/classrooms/events-list/events-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [MicrosoftLoginGuard, MicrosoftRolesGuard],
    data: {
      rolUsuario: [ 'Usuarios.SuperAdmin', 'Usuarios.Supervisor', 'Usuarios.Usuario']
    },
    children: [
      { 
        path: 'events',
        component: EventsListComponent
      },
      {
        path: 'start',
        loadChildren: () => import('./pages/start/start.module').then( m => m.StartModule )
      },
      {
        path: 'catalogs',
        loadChildren: () => import('./pages/classrooms/components/classrooms/classrooms.module').then( m => m.ClassroomsModule )
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'start'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
