import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  showNotification(type, titulo, mensaje, icon) {

    //TODO: Iconos generales
    //fas fa-times para el error
    //fas fa-check cuando todo esta bien
    //fas fa-exclamation cuando hay una advertencia

    if (type === 'danger') { type = 'red' }

    this.toastr.show(
      `<span class="alert-icon ${icon}" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">${titulo}</span> <span data-notify="message">${mensaje}</span></div>`,
      '',
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-center',
        toastClass: `ngx-toastr alert alert-dismissible alert-${type} alert-notify`,
      }
    );
  }
}
