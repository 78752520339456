import { Component, OnInit, ViewChild } from '@angular/core';
import { Apollo, gql                  } from 'apollo-angular';
import { NgbModal                     } from '@ng-bootstrap/ng-bootstrap';
import { Store                        } from '@ngrx/store';
import   Swal                           from 'sweetalert2';

import { EventModalComponent } from '../event-modal/event-modal.component'
import { NotificationService } from '../../../../../shared/services/notification.service';
import { selectorUserSession } from '../../../../../state/selectors/login.selector';
import { ClassroomsService   } from '../../../services/classrooms.service'
import { UserSession         } from '../../../../auth/interfaces/UserSession.interface';
import { AppState            } from '../../../../../state/app.state';
import { Event               } from '../../../interfaces/event-response';

@Component({
  selector   :  'app-list-events',
  templateUrl:  './events-list.component.html',
  styleUrls  : ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit {
  
  userSession: UserSession;
  
  @ViewChild('myTable') table: any;

  activeRow        : any;
  entries          : number  = 10;
  loader           : boolean = true;
  selected         : any[]   = [];
  dataEventsDb     : any[]   = [];
  dataEventsDbCopy : any[]   = [];
  users            : any[]   = [];
  employeeName     : String;

  breadcrumb = {
    route      : 'Eventos',
    icon       : 'fas fa-building',
    childRoute : 'Catálogos',
    title      : 'Eventos'
  }

  constructor(private modalService        : NgbModal, 
              private _classroomService   : ClassroomsService,
              private notificationService : NotificationService,
              private store               : Store<AppState>,
              private apollo              : Apollo,) { 
    this.store.select(selectorUserSession).subscribe((session: UserSession) => {
      this.userSession = session;
    });
  }
  
  // Tabla Global con GraphQL
  /// TABLA USUARIOS
  GET_USUARIOS= gql`
    query{
      ctlUsuarios{
        idUsuario
        numeroEmpleado
        idUnidadAdministrativa
        nombreEmpleado
      }
  }`;

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(){
    this.apollo
        .watchQuery({
          query: this.GET_USUARIOS,
        })
        .valueChanges.subscribe((result: any) => {
          result?.data.ctlUsuarios.forEach(usuario => {
            this.users.push({ numeroEmpleado: usuario.numeroEmpleado, nombreEmpleado: usuario.nombreEmpleado })
          });
          this.readAllEvents();
        })
  }

  createEvent( ) {
    const modalRef = this.modalService.open(EventModalComponent, { 
      // windowClass: 'modal-mini', 
      size: 'xl', 
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.titleModal = 'Agregar un nuevo elemento';

    modalRef.closed.subscribe( data => {
      if (data == 'saved') {
        this.readAllEvents();
      }
    });
  }

  sliceDescription(){

    this.dataEventsDb.forEach(element => {
      element.description = element.description.substring(0,100)+"...";
    });
  }

  readAllEvents(){
    this._classroomService.readAllEvents().subscribe({
      next: (resp) => {
        if (resp.data != null) {
          this.dataEventsDbCopy = resp.data;
          this.dataEventsDb = resp.data.map((prop, key) => {
            let objEvent:Event;
            this.users.forEach(user => {
              if (prop.idUsuario == user.numeroEmpleado) {
                let objUser = {
                  "nombreEmpleado": user.nombreEmpleado
                }
                objEvent = Object.assign(prop, objUser);
              }
            });
            if (objEvent == undefined) {
              let objUser = {
                "nombreEmpleado": "-"
              }
              objEvent = Object.assign(prop, objUser);
            }
            return {
              ...objEvent,
              id: key
            };
          });
        }
        if (resp.result == 0) {
          Swal.fire({
            icon:  'warning',
            title: 'Atención.',
            text:  resp.message,
          });
        }
        this.sliceDescription();
        this.loader = false;
      },
      error: (e) => {
        if (e.message === '0') {
          this.notificationService.showNotification('danger', 'Error', 'No hay comunicación con el servidor.', 'fa fa-close');
          this.dataEventsDb = [];
          this.loader = false;
        }else{
          this.notificationService.showNotification('danger', 'Error', e.error, 'fa fa-close');
        }
      }
    });
  }

  updateRecord(event:Event){
    const modalRef = this.modalService.open(EventModalComponent, { 
      // windowClass: 'modal-mini', 
      size: 'xl', 
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.titleModal = 'Editar elemento';
    modalRef.componentInstance.event = this.dataEventsDbCopy[event.idEvent-1];
    modalRef.closed.subscribe( data => {
      if (data == 'saved') {
        this.readAllEvents();
      }
    });
  }

  cancelEvent(event: any){
    Swal.fire({
      title: '¿Estás seguro de cancelar este evento?',
      text: "¡Esto no se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      customClass: {
        confirmButton: 'btn btn-red',
        cancelButton: 'btn btn-secondary'
      },
      buttonsStyling: false,
      reverseButtons: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let eventDTO = { ...event };
        eventDTO.idUsuario = +this.userSession.employeeId
        
        this._classroomService.deleteEvent(eventDTO).subscribe({
          next: (v) => {
            //Tipo, notificación, Titulo, Mensaje, Icono FontAwesome 6
            if (v.result == 1) {
              this.notificationService.showNotification('success', 'Éxito', v.message, 'fas fa-check');
            }else{
              this.notificationService.showNotification('danger', 'Error', v.message, 'fa fa-close');
            }
              
            this.readAllEvents();
          },
          error: (e) => {
            if (e.name === "HttpErrorResponse" && e.status === 0) {
              this.notificationService.showNotification('danger', 'Error', 'No hay comunicación con el servidor.', 'fa fa-close');
            }else{
              this.notificationService.showNotification('danger', 'Error', e.error, 'fa fa-close');
            }
          },
        });
      }
    })
  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value;
    this.dataEventsDb = this.dataEventsDbCopy.filter(function(d) {
      for (var key in d) {
        if ((d[key.toString()]).toString().toLowerCase().indexOf(val) !== -1) {
          return true;
        }
      }
      return false;
    });
  }

  getHeight(row: any): number {
    if(row) {
      return row.someHeight;
    }
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

}
