<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white">Cargando...</p>
</ngx-spinner>
<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title" id="modal-title-default">{{titleModal}}</h6>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <mat-grid-list cols="1" *ngIf="isLoading else divTypeEvents">
    <mat-grid-tile>
      <div class="col-auto p-5 text-center">
        <img src="assets/img/loader/loader.svg" />
        <h3 class="text-default"> Cargando...</h3>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <ng-template #divTypeEvents>
    <div class="modal-body">
      <div class="col-12">
        <form autocomplete="off" [formGroup]="form">
          <div class=" form-group">
            <label for="activity" class=" form-control-label">Actividad</label>
            <textarea
              [ngClass]="validarForm('activity') ? 'is-invalid' : form.controls['activity'].pristine ? '' : 'is-valid'" id="activity"
              formControlName="activity"
              id="activity"
              type="text"
              class="form-control"
              trim="blur" 
              rows="1"
              placeholder="Título de la actividad">
            </textarea>
            <span class="form-text text-danger" *ngIf="validarForm('activity')">Campo obligatorio</span>
            <span class="form-text text-danger" *ngIf="form.controls['activity'].hasError('minlength')">Mínimo tres caracteres</span>
          </div>
          <div class=" form-group">
            <label for="description" class=" form-control-label">Descripción</label>
            <textarea
                [ngClass]="validarForm('description') ? 'is-invalid' : form.controls['description'].pristine ? '' : 'is-valid'" id="description"
                formControlName="description"
                id="description"
                type="text"
                class="form-control"
                trim="blur" 
                rows="3"
                placeholder="Descripción de la actividad y requerimeintos">
            </textarea>
            <span class="form-text text-danger" *ngIf="validarForm('description')">Campo obligatorio</span>
            <span class="form-text text-danger" *ngIf="form.controls['description'].hasError('minlength')">Mínimo tres caracteres</span>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class=" form-group">
                <label class=" form-control-label">
                  Fecha:
                </label>
                <div class="input-group">
                
                <input  bsDaterangepicker
                        class="form-control"
                        [bsConfig]="bsConfig"
                        name="rangeDate"
                        [(ngModel)]="rangeDate"
                        type="text"
                        [minDate]="minDate"
                        id="rangeDate"
                        formControlName="rangeDate"
                        [ngClass]="validarForm('eventDate') ? 'is-invalid' : form.controls['eventDate'].pristine ? '' : 'is-valid'"
                        [daysDisabled]="[6,0]"
                >
                <span class="form-text text-danger" *ngIf="validarForm('eventDate')">Campo obligatorio</span>
              </div>
                <!-- {{bsRangeValue}} -->
              </div>

            </div>
            <div class="col-lg-4">
              <div class=" form-group">
                <label for="startHour" class=" form-control-label">Hora de Inicio</label>
                <input
                  [ngClass]="validarForm('startHour') ? 'is-invalid' : form.controls['startHour'].pristine ? '' : 'is-valid'" id="eventDate"
                  formControlName="startHour"
                  id="startHour"
                  type="time"
                  class="form-control"
                  trim="blur">
                <span class="form-text text-danger" *ngIf="validarForm('startHour')">Campo obligatorio</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class=" form-group">
                <label for="endHour" class=" form-control-label">Hora de finalización</label>
                <input
                  [ngClass]="validarForm('endHour') ? 'is-invalid' : form.controls['endHour'].pristine ? '' : 'is-valid'" id="eventDate"
                  formControlName="endHour"
                  id="endHour"
                  type="time"
                  class="form-control"
                  trim="blur">
                <span class="form-text text-danger" *ngIf="validarForm('endHour')">Campo obligatorio</span>
                <span class="form-text text-danger" *ngIf="form.controls['endHour'].hasError('minlength')">Mínimo tres caracteres</span>
              </div>
            </div>
          </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label" for="idPlace">
                Lugar:*
              </label>
              <label class="form-control valido" *ngIf="!places">
                <span class="spinner-border spinner-border-sm valido" role="status" aria-hidden="true"></span>
                Cargando...
              </label>
              <select *ngIf="places" class="form-control" id="idPlace" data-toggle="select"
                formControlName="idPlace"
                [ngClass]="{'is-invalido': validarForm('idPlace'),'is-valido': validarForm('idPlace') }" (change)="onChangePlace($event)">
                <option value="" disabled selected>-- Seleccione el Lugar --</option>
                <option *ngFor="let place of places" [value]="place.idPlace">
                  {{place.name}}
                </option>
              </select>
          </div>
          </div>
          <div class="col-lg-3">
            <div class=" form-group">
                <label for="attendeesNumber">Número de Asistentes</label>
                <input
                    [ngClass]="validarForm('attendeesNumber') ? 'is-invalid' : form.controls['attendeesNumber'].pristine ? '' : 'is-valid'" id="attendeesNumber"
                    formControlName="attendeesNumber"
                    id="attendeesNumber"
                    type="number"
                    class="form-control"
                    min="1"
                    max={{attenderNumber}}
                    trim="blur"
                    placeholder="0">
                <span class="form-text text-danger" *ngIf="validarForm('attendeesNumber')">Campo obligatorio, de 1 a {{attenderNumber}} asistentes.</span>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
                <label class="form-control-label" for="idDistribution">
                  Distribución:*
                </label>
                <label class="form-control valido" *ngIf="!distributions">
                  <span class="spinner-border spinner-border-sm valido" role="status" aria-hidden="true"></span>
                  Cargando...
                </label>
                <select *ngIf="distributions" class="form-control" id="idDistribution" data-toggle="select"
                  formControlName="idDistribution"
                  [ngClass]="{'is-invalido': validarForm('idDistribution'),'is-valido': validarForm('idDistribution') }">
                  <option value="" disabled selected>-- Seleccione la dustribución --</option>
                  <option *ngFor="let distribution of distributions" [value]="distribution.idDistribution">
                    <img alt="{{distribution.description}}" src="./assets/img/distribuciones/{{distribution.description}}.jpg" width="50px">
                    {{distribution.description}}
                  </option>
                </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group">
                <label class="form-control-label" for="idTypeEvent">
                  Tipo de Evento:*
                </label>
                <label class="form-control valido" *ngIf="!typeEvents">
                  <span class="spinner-border spinner-border-sm valido" role="status" aria-hidden="true"></span>
                  Cargando...
                </label>
                <select *ngIf="typeEvents" class="form-control" id="idTypeEvent" data-toggle="select"
                  formControlName="idTypeEvent"
                  [ngClass]="{'is-invalido': validarForm('idTypeEvent'),'is-valido': validarForm('idTypeEvent') }">
                  <option value="" disabled selected>-- Seleccione el Tipo de Evento --</option>
                  <option *ngFor="let typeEvent of typeEvents" [value]="typeEvent.idTypeEvent">
                    {{typeEvent.name}}
                  </option>
                </select>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="form-group">
                <label class="form-control-label" for="idActivityOrganizer">
                  Organizador:*
                </label>
                <label class="form-control valido" *ngIf="!activityOrganizers">
                  <span class="spinner-border spinner-border-sm valido" role="status" aria-hidden="true"></span>
                  Cargando...
                </label>
                <select *ngIf="activityOrganizers" class="form-control" id="idActivityOrganizer" data-toggle="select"
                  formControlName="idActivityOrganizer"
                  [ngClass]="{'is-invalido': validarForm('idActivityOrganizer'),'is-valido': validarForm('idActivityOrganizer') }">
                  <option value="" disabled selected>-- Seleccione el Organizador --</option>
                  <option *ngFor="let activityOrganizer of activityOrganizers" [value]="activityOrganizer.idActivityOrganizer">
                    {{activityOrganizer.description}}
                  </option>
                </select>
            </div>
          </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Cerrar</button>
        <button type="button" class="btn btn-danger" (click)="createEvents()" [disabled]="form.invalid">Guardar</button>
    </div>
  </ng-template>
</div>

<!-- <div class="col-md-6">
    <pre> Valid: {{form.valid}}</pre>
    <pre> Valid: {{form.value | json}}</pre>
</div> -->