import { NgbAlertModule, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS            } from "@angular/common/http";
import { BrowserAnimationsModule                        } from "@angular/platform-browser/animations";
import { LOCALE_ID, NgModule                            } from "@angular/core";
import { StoreDevtoolsModule                            } from '@ngrx/store-devtools';
import { registerLocaleData                             } from "@angular/common";
import { BsDropdownModule                               } from "ngx-bootstrap/dropdown";
import { TagInputModule                                 } from "ngx-chips";
import { CollapseModule                                 } from 'ngx-bootstrap/collapse';
import { GraphQLModule                                  } from './graphql.module';
import { BrowserModule                                  } from '@angular/platform-browser';
import { RouterModule                                   } from "@angular/router";
import { ToastrModule                                   } from "ngx-toastr";
import { FormsModule                                    } from "@angular/forms";
import { StoreModule                                    } from '@ngrx/store';
import   localeEs                                         from "@angular/common/locales/es";

//Azure
import { MSAL_INSTANCE, MsalService, MsalInterceptor, MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication                                 } from '@azure/msal-browser';
import { MicrosoftAuthService                                                                               } from "./pages/auth/services/microsoft-auth.service";

import { ModalEventDetailComponent } from './pages/classrooms/components/calendars/modal-event-detail/modal-event-detail.component';
import { AdminLayoutComponent       } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent        } from "./layouts/auth-layout/auth-layout.component";
import { AppRoutingModule           } from './app-routing.module';
import { ComponentsModule           } from "./components/components.module";
import { ROOT_REDUCERS              } from "./state/app.state";
import { AppComponent               } from "./app.component";
import { environment                } from 'src/environments/environment';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

registerLocaleData(localeEs, "es");

//Azure variables
const _authorityAzure           = environment.authorityAzure;
const _clientIdAzure            = environment.clientIdAzure;
const _me                       = environment.meAzure;
const _redirectApplicationAzure  = environment.redirectApplicationAzure;
const _scopesAzure              = environment.scopesAzure;

const _apiUrl = environment.apiUrl;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId   : _clientIdAzure,
      authority  : _authorityAzure,
      redirectUri: _redirectApplicationAzure,
    },
  })
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read', 'mail.read']);
  protectedResourceMap.set(_me, ['user.read']);
  protectedResourceMap.set(_apiUrl, [_scopesAzure]);
  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [
    AdminLayoutComponent,
    AppComponent,
    AuthLayoutComponent,
    ModalEventDetailComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ComponentsModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot({preventDuplicates: true}),
    CollapseModule.forRoot(),
    TagInputModule,
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(ROOT_REDUCERS),
    StoreDevtoolsModule.instrument({ name: 'AULAS STORE'}),
    GraphQLModule,
    NgbAlertModule,
    NgbDatepickerModule,
    NgbModule,
    NgxDatatableModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }, {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    }, {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MicrosoftAuthService,
    { provide: LOCALE_ID, useValue: "es" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
