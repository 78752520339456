import { catchError, delay, Observable, retry, throwError } from 'rxjs';
import { HttpClient, HttpHeaders                          } from '@angular/common/http';
import { Injectable                                       } from '@angular/core';
import { Store                                            } from '@ngrx/store';

import { ActivityOrganizer, ActivityOrganizerResponse } from '../interfaces/activity-organizer-response';
import { TypeEvent, TypeEventResponse                 } from '../interfaces/type-event-response';
import { DistributionResponse                         } from '../interfaces/distribution-response';
import { ActivityOrganizerDTO                         } from '../interfaces/activity-organizerDTO';
import { selectorUserSession                          } from 'src/app/state/selectors/login.selector';
import { DistributionDTO                              } from '../interfaces/distributionDTO';
import { PlaceResponse                                } from '../interfaces/place-response';
import { Distribution                                 } from '../interfaces/distribution';
import { environment                                  } from 'src/environments/environment';
import { TypeEventDTO                                 } from '../interfaces/type-eventDTO';
import { UserSession                                  } from '../../auth/interfaces/UserSession.interface';
import { PlaceDTO                                     } from '../interfaces/placeDTO';
import { AppState                                     } from 'src/app/state/app.state';
import { UserNotificationResponse, UserNotification } from '../interfaces/user-notification-response';
import { UserNotificationDTO } from '../interfaces/UserNotificationDTO';

const _url = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  
  userSession: UserSession;

  constructor(private http: HttpClient,
    private store: Store<AppState>) { 
      this.store.select(selectorUserSession).subscribe((session: UserSession) => {
        this.userSession = session;
      });
    }

    //#region ==========> DISTRIBUTIONS <==========
    createDistribution(distribution: Distribution): Observable<DistributionResponse>{
      return this.http.post<DistributionResponse>(`${_url}/distributions`, distribution);
    }
  
    readAllDistributions(): Observable<DistributionResponse>{
      return this.http.get<DistributionResponse>(`${_url}/distributions/${+this.userSession.employeeId}`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }
  
    readAllActiveDistributions(): Observable<DistributionResponse>{
      return this.http.get<DistributionResponse>(`${_url}/distributions/active/${+this.userSession.employeeId}`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }
    
    updateDistribution(distributionDTO: DistributionDTO): Observable<DistributionResponse>{
      return this.http.put<DistributionResponse>(`${_url}/distributions`, distributionDTO);
    }

    deleteDistribution(distributionDTO: DistributionDTO):Observable<DistributionResponse>{
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: distributionDTO,
      };
      return this.http.delete<DistributionResponse>(`${_url}/distributions`, options);
    }
  
    //#endregion

    //#region ==========> PLACES <==========
    createPlace(placeDTO: PlaceDTO): Observable<PlaceResponse>{
      return this.http.post<PlaceResponse>(`${_url}/places`, placeDTO);
    }

    readAllPlaces(): Observable<PlaceResponse>{
      return this.http.get<PlaceResponse>(`${_url}/places/${+this.userSession.employeeId}`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }

    updatePlace(placeDTO: PlaceDTO): Observable<PlaceResponse>{
      return this.http.put<PlaceResponse>(`${_url}/places`, placeDTO);
    }
  
    deletePlace(placeDTO: PlaceDTO):Observable<PlaceResponse>{
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: placeDTO,
      };
      return this.http.delete<PlaceResponse>(`${_url}/places`, options);
    }
  
    //#endregion

    //#region ==========> TYPE EVENTS <==========
   
    createTypeEvent(typeEvent: TypeEvent): Observable<TypeEventResponse>{
      return this.http.post<TypeEventResponse>(`${_url}/type-events`, typeEvent);
    }
  
    readAllTypeEvents(): Observable<TypeEventResponse>{
      return this.http.get<TypeEventResponse>(`${_url}/type-events/${+this.userSession.employeeId}`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }
    
    updateTypeEvent(typeEventDTO: TypeEventDTO): Observable<TypeEventResponse>{
      return this.http.put<TypeEventResponse>(`${_url}/type-events`, typeEventDTO);
    }

    deleteTypeEvent(typeEventDTO: TypeEventDTO):Observable<TypeEventResponse>{
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: typeEventDTO,
      };
      return this.http.delete<TypeEventResponse>(`${_url}/type-events`, options);
    }
  
    //#endregion

    //#region ==========> Activity Organizers <==========
    createActivityOrganizer(activityOrganizer: ActivityOrganizer): Observable<ActivityOrganizerResponse>{
      return this.http.post<ActivityOrganizerResponse>(`${_url}/activity-organizers`, activityOrganizer);
    }
  
    readAllActivityOrganizers(): Observable<ActivityOrganizerResponse>{
      return this.http.get<ActivityOrganizerResponse>(`${_url}/activity-organizers/${+this.userSession.employeeId}`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }
    
    updateActivityOrganizer(activityOrganizerDTO: ActivityOrganizerDTO): Observable<ActivityOrganizerResponse>{
      return this.http.put<ActivityOrganizerResponse>(`${_url}/activity-organizers`, activityOrganizerDTO);
    }

    deleteActivityOrganizer(activityOrganizerDTO: ActivityOrganizerDTO):Observable<ActivityOrganizerResponse>{
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: activityOrganizerDTO,
      };
      return this.http.delete<ActivityOrganizerResponse>(`${_url}/activity-organizers`, options);
    }
  
    //#endregion

    //#region ==========> User Notificaction <==========
  createUserNotification(userNotification: UserNotification): Observable<UserNotificationResponse>{
    return this.http.post<UserNotificationResponse>(`${_url}/user-notifications`, userNotification);
  }

  readAllUserNotifications(): Observable<UserNotificationResponse>{
    return this.http.get<UserNotificationResponse>(`${_url}/user-notifications/${+this.userSession.employeeId}`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }
  
  updateUserNotification(userNotificationDTO: UserNotificationDTO): Observable<UserNotificationResponse>{
    return this.http.put<UserNotificationResponse>(`${_url}/user-notifications`, userNotificationDTO);
  }

  deleteUserNotification(userNotificationDTO: UserNotificationDTO):Observable<UserNotificationResponse>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: userNotificationDTO,
    };
    return this.http.delete<UserNotificationResponse>(`${_url}/user-notifications`, options);
  }

  //#endregion

    handleError(error) {
      let statusError = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        statusError = error.status;
      } else {
        // server-side error
        statusError = error.status;
      }
      return throwError(() => new Error(statusError))
    }
}
