import { createAction, props } from "@ngrx/store";
import { UserSession } from "src/app/pages/auth/interfaces/UserSession.interface";

export const loadLogin = createAction(
    '[Login] Load Login Page'
);

export const loginSuccess = createAction(
    '[Login] Login Success',
    props<{userSession: UserSession }>()
);