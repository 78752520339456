import { Component, OnInit     } from "@angular/core";
import { Router                } from "@angular/router";
import { Store                 } from "@ngrx/store";

import { UserSession         } from "src/app/pages/auth/interfaces/UserSession.interface";
import { selectorUserSession } from "src/app/state/selectors/login.selector";

var misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path:          string;
  title:         string;
  type:          string;
  icontype:      string;
  collapse?:     string;
  isCollapsed?:  boolean;
  isCollapsing?: any;
  children?:     ChildrenItems[];
}

export interface ChildrenItems {
  path:         string;
  title:        string;
  type?:        string;
  collapse?:    string;
  isCollapsed?: boolean;
  children?:    ChildrenItems2[];
}
export interface ChildrenItems2 {
  path?:  string;
  title?: string;
  type?:  string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path     : "/",
    title    : "Inicio",
    type     : "link",
    icontype : "fas fa-home text-primary",
  },
  {
    path     : "events",
    title    : "Eventos",
    type     : "link",
    icontype : "fas fa-clipboard-list text-red",
  },
  {
    path     : "catalogs",
    title    : "Catálogos",
    type     : "sub",
    icontype : "fas fa-building text-green",
    collapse : "aulas",
    isCollapsed: true,
    children: [
      { path: "activities-organizers",  title: "Organizadores de Activiades", type: "link" },
      { path: "distributions",          title: "Distribuciones",              type: "link" },
      { path: "events-type",            title: "Tipo de Eventos",             type: "link" },
      { path: "places",                 title: "Lugares",                     type: "link" },
      { path: "users-notification",     title: "Notificaciones de usuarios",  type: "link" },
    ]
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;

  rolesUsuario: [];


  constructor(private router: Router, private store: Store) {
    this.store.select(selectorUserSession).subscribe((sesion: UserSession) => {
      this.rolesUsuario = JSON.parse(sesion.roles);
    });
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });
  }

  usuarioRol(rolUsuario: string){
    const rol = this.rolesUsuario.filter(element => element === `Usuarios.${rolUsuario}`);
    return rol.length > 0 ? true : false;
  }

  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName(
      "sidenav-toggler"
    )[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }
}
