import { createReducer, on } from '@ngrx/store';
import { UserSession } from '../../pages/auth/interfaces/UserSession.interface';
import { loginSuccess, loadLogin } from '../actions/login.actions';
import { loginState } from '../interfaces/loginState.interface';


export const initialState: loginState = { loading: false, userSession: {}}

export const loginReducer = createReducer(
    initialState,
    on(loadLogin, (state) => {
        return {...state, loading:true };
    }),
    on(loginSuccess, (state, {userSession: userSession}) => {
        return {...state, loading: false, userSession: userSession};
    })
)